import { io } from "socket.io-client";
import { socketServer } from "./Server";

const socketConnection = io(socketServer, {
  autoConnect: false,
  auth: {
    token: "Hihellohi",
  },
});

export default socketConnection;

import useSWR from "swr";
import { fetcher } from "./base";

export interface User {
  id: string;
  name?: string;
  email?: string;
  emailVerified?: boolean;
  image?: string;
  apiSecret?: string;
  stripeId?: string;
  isPremium?: boolean;
}

export interface UserProps {
  user: User;
  isLoading: boolean;
}

export function useUser() {
  const { data: user, error, isLoading } = useSWR(`/api/auth/session`, fetcher, {});

  let isLoggedIn = false;
  if (user) {
    isLoggedIn = Object.keys(user).length !== 0;
  }

  return {
    user,
    error,
    isLoading,
    isLoggedIn,
  };
}

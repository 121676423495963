import * as React from "react";
import type { AppProps } from "next/app";
import { CacheProvider, EmotionCache } from "@emotion/react";
import { ThemeProvider, CssBaseline, createTheme, useMediaQuery, Theme } from "@mui/material";
import { SessionProvider } from "next-auth/react";
import ErrorBoundary from "../components/ErrorBoundary";
import { usePathname } from "next/navigation";
import { SpeedInsights } from "@vercel/speed-insights/next";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import createEmotionCache from "../utility/createEmotionCache";
import darkThemeOptions from "../styles/theme/darkThemeOptions";
import "../styles/globals.css";
import lightThemeOptions from "../styles/theme/lightThemeOptions";
import { SnackbarProvider } from "notistack";
import { useCookies } from "react-cookie";
import socketConnection from "../components/Socket";
import { useUser } from "../components/fetchers/useUser";
interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

const clientSideEmotionCache = createEmotionCache();

const darkTheme = createTheme(darkThemeOptions);
const lightTheme = createTheme(lightThemeOptions);

export type Provider = {
  provider: string;
};

export type Data = {
  artistColor: string;
  backgroundColor: string;
  backgroundRounding: number;
  borderColor: string;
  borderType: string;
  borderWidth: number;
  showPlayingIndicator: boolean;
  showWhenPaused: boolean;
  titleColor: string;
};

export type MainProps = {
  activeTheme: Theme;
  changeTheme: () => void;
  user: {
    id: string;
    email: string;
    name: string;
    image: string;
    accounts: Provider[];
    apiSecret: string;
  };
  util: {
    id: string;
    name: string;
    active: boolean;
    error: boolean;
    errorMsg: string;
    data: Data;
  };
};

function getActiveTheme(themeMode: "light" | "dark") {
  return themeMode === "light" ? lightTheme : darkTheme;
}

const THEME_PREFERENCE_COOKIE_NAME = "theme-preference";

const MyApp: React.FunctionComponent<MyAppProps> = (props) => {
  const {
    Component,
    emotionCache = clientSideEmotionCache,
    pageProps: { session, ...pageProps },
  } = props;
  const [activeTheme, setActiveTheme] = React.useState(darkTheme);
  const [cookieTheme, setCookieTheme] = useCookies([THEME_PREFERENCE_COOKIE_NAME]);

  const defaultInitialTheme = "dark";
  const preferredTheme =
    cookieTheme && cookieTheme[THEME_PREFERENCE_COOKIE_NAME]
      ? cookieTheme[THEME_PREFERENCE_COOKIE_NAME]
      : defaultInitialTheme;

  const [selectedTheme, setSelectedTheme] = React.useState<"light" | "dark">(preferredTheme);

  const { user, isLoading: loadingUser } = useUser();

  const pathname = usePathname();

  const changeTheme = () => {
    const desiredTheme = selectedTheme === "light" ? "dark" : "light";

    setSelectedTheme(desiredTheme);
    setCookieTheme(THEME_PREFERENCE_COOKIE_NAME, desiredTheme);
  };

  React.useEffect(() => {
    setActiveTheme(getActiveTheme(selectedTheme));
  }, [selectedTheme]);

  React.useEffect(() => {
    if (!user || loadingUser) return;

    if (user?.apiSecret) {
      socketConnection.auth = { token: user.apiSecret };
      socketConnection.connect();
    }

    return () => {
      socketConnection.disconnect();
    };
  }, [user]);

  React.useEffect(() => {
    let progress = document.getElementById("progress-bar");
    let totalHeight = document.body.scrollHeight - window.innerHeight;

    const handleScroll = () => {
      let progressHeight = (window.scrollY / totalHeight) * 100;

      if (progress) {
        progress.style.height = `${progressHeight}%`;
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [pathname]);

  return (
    <ErrorBoundary>
      <CacheProvider value={emotionCache}>
        <SessionProvider session={session}>
          <ThemeProvider theme={activeTheme}>
            <SnackbarProvider maxSnack={5} autoHideDuration={2500} preventDuplicate>
              <CssBaseline />
              <Component {...pageProps} activeTheme={activeTheme} changeTheme={changeTheme} />
            </SnackbarProvider>
          </ThemeProvider>
        </SessionProvider>
      </CacheProvider>
    </ErrorBoundary>
  );
};

export default MyApp;
